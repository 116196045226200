body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "siegeLike";
  src: url(/static/media/ScoutCond-Light.e7726be0.otf);

}

body {
  color: white;
  text-align: center;
}

p {
  font-family: "siegeLike";
  font-size: 30px;
  letter-spacing: 2px;
}
h1 {
  font-family: "siegeLike";
  letter-spacing: 5px;
  font-size: 60px;
}
h2 {
  font-family: "siegeLike";
  letter-spacing: 5px;
  font-size: 40px;
}
.contentContainer {
  margin: 72px auto 0;
  width: 100%;
  background: #0D0C13;
}
@media (max-width: 800px) {
  .contentContainer {
    margin: 54px auto 0;
  }
}
@media (max-width: 580px) {
  .contentContainer {
    margin: 36px auto 0;
  }
}
@media (max-width: 480px) {
  p {
    font-size: 23px;

  }
  h1 {
    font-size: 40px;
  }
}
@media (max-width: 414px) {
  .contentContainer {
    margin: 45px auto 0;

  }
}
.bannerContainer {
	z-index: 10;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: black;
	box-shadow: 0px 0px 30px 0px rgba(0,0,0,1);
	-webkit-user-select: none;
	        user-select: none;
}
.bannerContainer a {
	text-decoration: none
}
.bannerContainer p {
	text-decoration: none;
	margin: 0 0 0 60px;
	font-size: 60px;
	-webkit-user-select: none;
	        user-select: none;
	color: white;
}
.bannerContainer nav {
	margin-right: 100px;
	display: flex;
	flex-direction: row;
}
.bannerContainer nav a {
	padding: 0 10px 0 10px;
	text-decoration: none;
	font-family: 'siegeLike';
	font-size: 30px;
	color: white;
	transition: 0.3s ease;
}
.bannerContainer nav a:hover {
	background-image: linear-gradient(to right, black 0%, #2A2A2A 45%, #2A2A2A 55%, black 100%);
	cursor: pointer;
	transition: 0.3s ease;
}
@media (max-width: 800px) {
	.bannerContainer {
		height: 55px;
	}
	.bannerContainer p {
		font-size: 40px;
		margin-left: 12px;
	}
	.bannerContainer nav {
		margin-right: 15px;
	}
	.bannerContainer nav a {
		font-size: 25px;
	}
}

@media (max-width: 460px) {
	.bannerContainer {
		height: 45px;
	}
	.bannerContainer p {
		font-size: 30px;
		margin-left: 12px;
	}
	.bannerContainer nav {
		margin-right: 15px;
	}
	.bannerContainer nav a {
		font-size: 20px;
	}
}
@media (max-width: 372px) {
	.bannerContainer p {
		font-size: 25px;
	}
	.bannerContainer nav a {
		font-size: 16px;
	}
	.bannerContainer nav {
		margin-right: 5px;
	}
}
.homepageContainer p {
	margin: 20px 10%;
}

.multiCarouselContainer {
	position: relative;
}

.multiCarouselContainer h1 {
	font-size: 70px;
	width: 100%;
	z-index: 2;
	position: absolute;
	bottom: 0;
	background: rgba(0,0,0,0.5);
}


.multiCarouselImage {
	width: 100%;
	height: 80vh;
	text-align: center;
}

.aboutText h2 {
	font-size: 70px;
	margin-bottom: 30px;
}
.aboutText p {
	font-size: 40px;
}
.mapListContainer {
	border-bottom: 1px solid #373737;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
}
.mapListContainer h1 {
	font-size: 70px;
}
.mapList {
	margin-bottom: 30px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
}
.branding {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	height: 20vh;
	background: black;
}

.branding h1 {
	margin: 0;
}
.branding ul {
	padding: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 0;
}
.branding ul a {
	font-size: 24px;
	padding: 0 20px 0 20px;
	cursor: pointer;
	text-decoration: none;
	color: white;
}
.branding ul a:link{
	color: white;
	text-decoration: none;
}
.backToTop {
	font-size: 20px;
	padding: 0 20px 0 20px;
	cursor: pointer;
	text-decoration: none;
	color: white;
	margin-bottom: 10px;
}


@media (max-width: 1295px) {
	.branding h1 {
		font-size: 4vw;
	}
	.branding ul a {
		font-size: 2vw;
	}
	.backToTop {
		font-size: 1.7vw;
	}
}

@media (max-width: 830px) {
	.multiCarouselContainer h1 {
		font-size: 50px;
	}
}

@media (max-width: 768px) {
	.branding h1 {
		font-size: 7vw;
	}
	.branding ul a {
		font-size: 3.5vw;
	}
	.backToTop {
		font-size: 3vw;
	}
	.multiCarouselImage {
		max-height: 50vh;
	}
}
@media (max-width: 490px) {
	.branding h1 {
		font-size: 10vw;
	}
	.branding ul a {
		font-size: 5vw;
	}
	.backToTop {
		font-size: 4.5vw;
	}
	.multiCarouselContainer h1 {
		font-size: 30px;
	}
}



.map {
	margin: 15px;
	position: relative;
	height: 225px;
	width: 500px;
	transition: 0.5s;
	-webkit-user-select: none;
	        user-select: none;
	border: 3px solid #373737;
}
.map:hover {
	transition: 0.5s;
	border: 3px solid #7D7676;
}
.map img {
	height: 100%;
	width: 100%;
	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
}
.map p {
	position: absolute;
	margin: 0;
	bottom: 0;
	font-size: 30px;
	left: 50%;
	width: 100%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	        transform: translateX(-50%) translateY(-50%);
	text-align: center;
	background: rgba(0,0,0,0.7);
	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
	color: white;
}

@media (max-width: 1625px) {
	.map {
		height: 202.5px;
		width: 450px;
	}
}
@media (max-width: 1475px) {
	.map {
		height: 180px;
		width: 400px;
	}
}
@media (max-width: 1325px) {
	.map {
		height: 157.5px;
		width: 350px;
	}
}
@media (max-width: 1175px) {
	.map {
		height: 202.5px;
		width: 450px;
	}
}
@media (max-width: 990px) {
	.map {
		height: 180px;
		width: 400px;
	}
}
@media (max-width: 890px) {
	.map {
		height: 157.5px;
		width: 350px;
	}
}
@media (max-width: 790px) {
	.map {
		height: 135px;
		width: 300px;
	}
}
@media (max-width: 690px) {
	.map {
		height: 127.5px;
		width: 250px;
	}
}
@media (max-width: 590px) {
	.map {
		height: auto;
		width: 80vw;
	}
}

.headerContainer {
	width: 100%;
	height: 650px;
	position: relative;
	border-bottom: 1px solid #7D7676;
}
.headerContainer h1 {
	position: absolute;
	width: 100%;
	margin: 0;
	top: 50%;
	left: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	        transform: translateX(-50%) translateY(-50%);
	text-align: center;
	font-size: 90px;
	background: rgba(0,0,0,0.7);
	letter-spacing: 15px;
}

@media (max-width: 1280px) {
	.headerContainer {
		height: 500px;
	}
	.headerContainer h1 {
		font-size: 50px;
	}
}
@media (max-width: 1060px) {
	.headerContainer {
		height: 400px;
	}
}
@media (max-width: 750px) {
	.headerContainer {
		height: 300px;
	}
}
@media (max-width: 480px) {
	.headerContainer {
		height: 200px;
	}
	.headerContainer h1 {
		font-size: 30px;
	}
}

.arrowContainer {

}
.slide-arrow {
	border: solid #558383;
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 3px;
    height: 16px;
    width: 16px;
    cursor: pointer;
}
.slide-arrow:hover {
	transition: 0.3s;
	border-color: #223535;
}
.right {
	position: absolute;
	right: -10px;
	bottom: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	        transform: translateX(-50%) translateY(-50%);
	transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.left {
	position: absolute;
	left: -10px;
	bottom: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	        transform: translateX(-50%) translateY(-50%);
	transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
@media (max-width: 480px) {
	.slide-arrow {
		border-width: 0 8px 8px 0;
		height: 8px;
		width: 8px;
	}
	.right {
		right: -8px;
	}
	.left {
		left: -8px;
	}
}
.scroll {
	overflow-y: scroll;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	overflow-y: scroll;
	border: 5px solid black; 
	height: 600px;
	max-width: 700px;
	margin: 10px 25px;
}
.scroll::-webkit-scrollbar {
    width: 1em;
}
 
.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
.scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
@media (max-width: 1060px) {
	.scroll {
		margin: 0 25px;
		height: 400px;
		max-width: none;
	}
}
@media (max-width: 480px) {
	.scroll {
		margin: 0 15px;
		height: 250px;
	}
}
.floorNav {
	position: absolute;
	right: 25px;
	top: -33px;
	display: flex;
	flex-direction: row;
}
.floorNav li {
	cursor: pointer;
	list-style-type: none;
	padding: 10px 20px;
}
.floorNav li:hover {
	transition: ease 0.3s;
	background: #223535;
}
.fullBorder {
	border: 2px solid black;
}
.noBorderLeft {
	border: 2px solid black;
	border-left: none;
}
.desktopSkewedNavButton {
	position: relative;
	z-index: 2;
	right: 70px;
	-webkit-transform: skewX(-30deg);
	        transform: skewX(-30deg);
}
.desktopSkewedNavButton a {
	-webkit-transform: skewX(30deg);
	        transform: skewX(30deg); 
	display: block;
}
.desktopUnskewedNavButton {
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	right: 0;
	width: 50px;
}
.floorNavButton {
	background: #4E7878;
	/*possibly use #7D0035*/
}
.floorNavButton-active {
	transition: ease 0.3s;
	background: #223535;
}
.floorNavButton-active:before {
	transition: ease 0.3s;
	background: #223535;	
}
.floorNavMobile {
	display: flex;
	flex-direction: row;
	justify-content: center;
	list-style-type: none;
}
.floorNavMobile li {
	cursor: pointer;
	padding: 10px 30px;
}
.floorNavMobile li:hover {
	transition: ease 0.3s;
	background: #223535;	
}
@media (max-width: 1060px) {
	.bottomFloorSkew {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		width: 20px;
		z-index: 1;
	}
	.bottomFloorSkew:before {
		display: block;
		-webkit-transform: skew(-30deg);
		        transform: skew(-30deg);
		width: 100%;
		height: 100%;
		content: '';
		position: absolute;
		background: #4E7878;
		top: -2px;
		right: 12px;
		z-index: -1;
		border: 2px solid black;
	}
	.bottomFloorSkew:after {
		display: block;
		width: 100%;
		height: 100%;
		content: '';
		position: absolute;
		background: #4E7878;
		top: 0px;
		left: 0px;
		z-index: -1;
	}
	.bottomFloorSkew:hover:before {
		transition: ease 0.3s;
		background: #223535;
	}
	.bottomFloorSkew:hover:after {
		transition: ease 0.3s;
		background: #223535;
	}
	.bottomFloorSkew.floorNavButton-active:before {
		transition: ease 0.3s;
		background: #223535;
	}
	.bottomFloorSkew.floorNavButton-active:after {
		transition: ease 0.3s;
		background: #223535;
	}
	.topFloorSkew {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		width: 20px;
		z-index: 1;
	}
	.topFloorSkew:after {
		display: block;
		width: 100%;
		height: 100%;
		content: '';
		position: absolute;
		background: #4E7878;
		top: 0px;
		left: 0px;
		z-index: -1;
	}
	.topFloorSkew:before {
		display: block;
		-webkit-transform: skew(30deg);
		        transform: skew(30deg);
		width: 100%;
		height: 100%;
		content: '';
		position: absolute;
		background: #4E7878;
		top: -2px;
		left: 12px;
		z-index: -1;
		border: 2px solid black;
	}
	.topFloorSkew:hover:before {
		transition: ease 0.3s;
		background: #223535;
	}
	.topFloorSkew:hover:after {
		transition: ease 0.3s;
		background: #223535;
	}
	.topFloorSkew.floorNavButton-active:after {
		transition: ease 0.3s;
		background: #223535;
	}
	.topFloorSkew.floorNavButton-active:before {
		transition: ease 0.3s;
		background: #223535;
	}
	.desktop {
		display: none;
	}
	.phone {
		display: flex;
	}
}
@media(max-width: 480px) {
	.floorNavMobile li {
		padding: 5px 10px;
	}
}

.lightBoxContainer {
	display: none;
}
.lightBoxContainer-active {
	z-index: 100;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background: rgba(0,0,0,0.9);
	cursor: pointer;
}
.lightBoxPictureContainer {
	margin: 15px auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.lightBoxPicture {
	width: 90vw;
}
@media(max-width: 480px) {
	.lightBoxPicture {
		width: 100vw;
	}
}
.imageContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.imageContainer img {
	margin: 10px;
	height: auto;
	width: 95%;
	cursor: pointer;
}
.loadingBox {
	margin: 10px;
	height: 20vh;
	width: 30vw;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: #110E1A;
	-webkit-animation: pulse 2.5s infinite;
	        animation: pulse 2.5s infinite;
}
@-webkit-keyframes pulse {
	0% {background: #110E1A;}
	50% {background: #000000;}
	100% {background: #110E1A;}
}
@keyframes pulse {
	0% {background: #110E1A;}
	50% {background: #000000;}
	100% {background: #110E1A;}
}
@media (max-width: 1060px) {
	.loadingBox {
		height: 30vh;
		width: 65vw;
	}
}
@media (max-width: 480px) {
	.imageContainer img {
		margin: 5px;
	}
}
.dotsContainer {
	display: flex;
	flex-direction: row;
	margin-left: 25px;
}
.dot {
	height: 15px;
	width: 15px;
	border-radius: 50%;
	background: #558383;
	margin: 5px;
	margin-bottom: 15px;
	cursor: pointer;
	transition: 0.5s;
}
.dot:hover {
	transition: 0.5s;
	background: #223535;
}
.dot-active {
	transition: 0.3s;
	background: #223535;
}

@media (max-width: 1060px) {
	.dot {
		margin-bottom: 5px;
	}
}
.anglesHeader {
	margin-top: 0px;
	padding-top: 35px;
}
.carouselContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0 30px;
}
.anglesContainer {
	margin-top: 45px;
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.anglesText {
	margin: 20px;
	font-size: 30px;
}

.textContainer {
	position: relative;
}
.desktop {
	display: flex;
}
.phone {
	display: none;
}
@media (max-width: 1060px) {
	.anglesContainer {
		flex-direction: column;
		margin-top: 0;
	}
	.anglesText {
		margin: 0px 20px;
	}
	.desktop {
		display: none;
	}
	.phone {
		display: flex;
	}
}

