.arrowContainer {

}
.slide-arrow {
	border: solid #558383;
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 3px;
    height: 16px;
    width: 16px;
    cursor: pointer;
}
.slide-arrow:hover {
	transition: 0.3s;
	border-color: #223535;
}
.right {
	position: absolute;
	right: -10px;
	bottom: 50%;
	transform: translateX(-50%) translateY(-50%);
	transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.left {
	position: absolute;
	left: -10px;
	bottom: 50%;
	transform: translateX(-50%) translateY(-50%);
	transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
@media (max-width: 480px) {
	.slide-arrow {
		border-width: 0 8px 8px 0;
		height: 8px;
		width: 8px;
	}
	.right {
		right: -8px;
	}
	.left {
		left: -8px;
	}
}