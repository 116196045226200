.headerContainer {
	width: 100%;
	height: 650px;
	position: relative;
	border-bottom: 1px solid #7D7676;
}
.headerContainer h1 {
	position: absolute;
	width: 100%;
	margin: 0;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	text-align: center;
	font-size: 90px;
	background: rgba(0,0,0,0.7);
	letter-spacing: 15px;
}

@media (max-width: 1280px) {
	.headerContainer {
		height: 500px;
	}
	.headerContainer h1 {
		font-size: 50px;
	}
}
@media (max-width: 1060px) {
	.headerContainer {
		height: 400px;
	}
}
@media (max-width: 750px) {
	.headerContainer {
		height: 300px;
	}
}
@media (max-width: 480px) {
	.headerContainer {
		height: 200px;
	}
	.headerContainer h1 {
		font-size: 30px;
	}
}
