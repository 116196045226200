.scroll {
	overflow-y: scroll;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	overflow-y: scroll;
	border: 5px solid black; 
	height: 600px;
	max-width: 700px;
	margin: 10px 25px;
}
.scroll::-webkit-scrollbar {
    width: 1em;
}
 
.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
.scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
@media (max-width: 1060px) {
	.scroll {
		margin: 0 25px;
		height: 400px;
		max-width: none;
	}
}
@media (max-width: 480px) {
	.scroll {
		margin: 0 15px;
		height: 250px;
	}
}