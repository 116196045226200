.homepageContainer p {
	margin: 20px 10%;
}

.multiCarouselContainer {
	position: relative;
}

.multiCarouselContainer h1 {
	font-size: 70px;
	width: 100%;
	z-index: 2;
	position: absolute;
	bottom: 0;
	background: rgba(0,0,0,0.5);
}


.multiCarouselImage {
	width: 100%;
	height: 80vh;
	text-align: center;
}

.aboutText h2 {
	font-size: 70px;
	margin-bottom: 30px;
}
.aboutText p {
	font-size: 40px;
}
.mapListContainer {
	border-bottom: 1px solid #373737;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
}
.mapListContainer h1 {
	font-size: 70px;
}
.mapList {
	margin-bottom: 30px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
}
.branding {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	height: 20vh;
	background: black;
}

.branding h1 {
	margin: 0;
}
.branding ul {
	padding: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 0;
}
.branding ul a {
	font-size: 24px;
	padding: 0 20px 0 20px;
	cursor: pointer;
	text-decoration: none;
	color: white;
}
.branding ul a:link{
	color: white;
	text-decoration: none;
}
.backToTop {
	font-size: 20px;
	padding: 0 20px 0 20px;
	cursor: pointer;
	text-decoration: none;
	color: white;
	margin-bottom: 10px;
}


@media (max-width: 1295px) {
	.branding h1 {
		font-size: 4vw;
	}
	.branding ul a {
		font-size: 2vw;
	}
	.backToTop {
		font-size: 1.7vw;
	}
}

@media (max-width: 830px) {
	.multiCarouselContainer h1 {
		font-size: 50px;
	}
}

@media (max-width: 768px) {
	.branding h1 {
		font-size: 7vw;
	}
	.branding ul a {
		font-size: 3.5vw;
	}
	.backToTop {
		font-size: 3vw;
	}
	.multiCarouselImage {
		max-height: 50vh;
	}
}
@media (max-width: 490px) {
	.branding h1 {
		font-size: 10vw;
	}
	.branding ul a {
		font-size: 5vw;
	}
	.backToTop {
		font-size: 4.5vw;
	}
	.multiCarouselContainer h1 {
		font-size: 30px;
	}
}


