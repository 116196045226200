.anglesHeader {
	margin-top: 0px;
	padding-top: 35px;
}
.carouselContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0 30px;
}
.anglesContainer {
	margin-top: 45px;
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.anglesText {
	margin: 20px;
	font-size: 30px;
}

.textContainer {
	position: relative;
}
.desktop {
	display: flex;
}
.phone {
	display: none;
}
@media (max-width: 1060px) {
	.anglesContainer {
		flex-direction: column;
		margin-top: 0;
	}
	.anglesText {
		margin: 0px 20px;
	}
	.desktop {
		display: none;
	}
	.phone {
		display: flex;
	}
}
