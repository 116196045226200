.floorNav {
	position: absolute;
	right: 25px;
	top: -33px;
	display: flex;
	flex-direction: row;
}
.floorNav li {
	cursor: pointer;
	list-style-type: none;
	padding: 10px 20px;
}
.floorNav li:hover {
	transition: ease 0.3s;
	background: #223535;
}
.fullBorder {
	border: 2px solid black;
}
.noBorderLeft {
	border: 2px solid black;
	border-left: none;
}
.desktopSkewedNavButton {
	position: relative;
	z-index: 2;
	right: 70px;
	transform: skewX(-30deg);
}
.desktopSkewedNavButton a {
	transform: skewX(30deg); 
	display: block;
}
.desktopUnskewedNavButton {
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	right: 0;
	width: 50px;
}
.floorNavButton {
	background: #4E7878;
	/*possibly use #7D0035*/
}
.floorNavButton-active {
	transition: ease 0.3s;
	background: #223535;
}
.floorNavButton-active:before {
	transition: ease 0.3s;
	background: #223535;	
}
.floorNavMobile {
	display: flex;
	flex-direction: row;
	justify-content: center;
	list-style-type: none;
}
.floorNavMobile li {
	cursor: pointer;
	padding: 10px 30px;
}
.floorNavMobile li:hover {
	transition: ease 0.3s;
	background: #223535;	
}
@media (max-width: 1060px) {
	.bottomFloorSkew {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		width: 20px;
		z-index: 1;
	}
	.bottomFloorSkew:before {
		display: block;
		transform: skew(-30deg);
		width: 100%;
		height: 100%;
		content: '';
		position: absolute;
		background: #4E7878;
		top: -2px;
		right: 12px;
		z-index: -1;
		border: 2px solid black;
	}
	.bottomFloorSkew:after {
		display: block;
		width: 100%;
		height: 100%;
		content: '';
		position: absolute;
		background: #4E7878;
		top: 0px;
		left: 0px;
		z-index: -1;
	}
	.bottomFloorSkew:hover:before {
		transition: ease 0.3s;
		background: #223535;
	}
	.bottomFloorSkew:hover:after {
		transition: ease 0.3s;
		background: #223535;
	}
	.bottomFloorSkew.floorNavButton-active:before {
		transition: ease 0.3s;
		background: #223535;
	}
	.bottomFloorSkew.floorNavButton-active:after {
		transition: ease 0.3s;
		background: #223535;
	}
	.topFloorSkew {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		width: 20px;
		z-index: 1;
	}
	.topFloorSkew:after {
		display: block;
		width: 100%;
		height: 100%;
		content: '';
		position: absolute;
		background: #4E7878;
		top: 0px;
		left: 0px;
		z-index: -1;
	}
	.topFloorSkew:before {
		display: block;
		transform: skew(30deg);
		width: 100%;
		height: 100%;
		content: '';
		position: absolute;
		background: #4E7878;
		top: -2px;
		left: 12px;
		z-index: -1;
		border: 2px solid black;
	}
	.topFloorSkew:hover:before {
		transition: ease 0.3s;
		background: #223535;
	}
	.topFloorSkew:hover:after {
		transition: ease 0.3s;
		background: #223535;
	}
	.topFloorSkew.floorNavButton-active:after {
		transition: ease 0.3s;
		background: #223535;
	}
	.topFloorSkew.floorNavButton-active:before {
		transition: ease 0.3s;
		background: #223535;
	}
	.desktop {
		display: none;
	}
	.phone {
		display: flex;
	}
}
@media(max-width: 480px) {
	.floorNavMobile li {
		padding: 5px 10px;
	}
}
