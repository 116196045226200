.imageContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.imageContainer img {
	margin: 10px;
	height: auto;
	width: 95%;
	cursor: pointer;
}
.loadingBox {
	margin: 10px;
	height: 20vh;
	width: 30vw;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: #110E1A;
	animation: pulse 2.5s infinite;
}
@keyframes pulse {
	0% {background: #110E1A;}
	50% {background: #000000;}
	100% {background: #110E1A;}
}
@media (max-width: 1060px) {
	.loadingBox {
		height: 30vh;
		width: 65vw;
	}
}
@media (max-width: 480px) {
	.imageContainer img {
		margin: 5px;
	}
}